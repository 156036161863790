import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { useGlobalContext } from "./GlobalContext";
import LoginForm from "./components/LoginForm";
import LivenessComponent from "./components/LivenessComponent";
import LoginKeyComponent from "./components/LoginKeyComponent";

export const AppRoutes = () => {
  const { isAuthenticated } = useGlobalContext();

  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={
            isAuthenticated ? (
              <Navigate to="/liveness" replace />
            ) : (
              <LoginForm />
            )
          }
        />
        <Route
          path="/liveness"
          element={
            isAuthenticated ? (
              <LivenessComponent />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route path="/login-key/:key" element={<LoginKeyComponent />} />
        <Route
          path="/"
          element={
            isAuthenticated ? (
              <Navigate to="/liveness" replace />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
      </Routes>
    </Router>
  );
};
