import React, { useCallback, useEffect, useState, useRef } from "react";
import { FaceLivenessDetector } from "@aws-amplify/ui-react-liveness";
import {
  View,
  Flex,
  Loader,
  Alert,
  Button,
  useTheme,
  Card,
  Text,
  Image,
} from "@aws-amplify/ui-react";
import "./LivenessComponent.css";
import { API_URL } from "../config";
import { useGlobalContext } from "../GlobalContext";
import { CustomAlertComponent } from "./alert/CustomAlertComponent";
import { TimeoutComponent } from "./alert/TimeOutComponent";
import { getAppCheckToken } from "../utils/helper";

const dictionary = {
  en: null,
  es: {
    photosensitivyWarningHeadingText: "Advertencia de fotosensibilidad",
    photosensitivyWarningBodyText:
      "Alerta de luces de colores. Ten precaución si eres fotosensible.",
    goodFitCaptionText: "Buen ajuste",
    tooFarCaptionText: "Demasiado lejos",
    hintCenterFaceText: "Centra tu cara",
    startScreenBeginCheckText: "Comenzar a verificar",
  },
  pt: {
    photosensitivyWarningHeadingText: "Aviso de fotosensibilidade",
    photosensitivyWarningBodyText:
      "Alerta de luz colorida. Tenha cuidado se você for fotossensível.",
    goodFitCaptionText: "Bom ajuste",
    tooFarCaptionText: "Muito longe",
    hintCenterFaceText: "Centralize seu rosto",
    startScreenBeginCheckText: "Começar a verificar",
    timeoutHeaderText: "tempo esgotado",
    timeoutMessageText:
      "O rosto não cabia dentro do oval no prazo. Tente novamente e preencha completamente o oval com o rosto dentro dele.",
    hintMoveFaceFrontOfCameraText:
      "Por favor, mova o rosto para a frente da câmera.",
    hintTooManyFacesText:
      "Detectamos muitos rostos. Por favor, posicione apenas um rosto na câmera.",
    hintFaceDetectedText: "Rosto detectado.",
    hintCanNotIdentifyText: "Não foi possível identificar o rosto.",
    hintTooCloseText: "Afaste-se um pouco.",
    hintTooFarText: "aproxime-se",
    hintConnectingText: "Conectando...",
    hintVerifyingText: "Verificando...",
    hintCheckCompleteText: "Verificação completa.",
    hintIlluminationTooBrightText: "A iluminação está muito brilhante.",
    hintIlluminationTooDarkText: "A iluminação está muito escura.",
    hintIlluminationNormalText: "Iluminação normal.",
    hintHoldFaceForFreshnessText: "Não se mova",
    // hintCenterFaceText: "Centralize o rosto.",
    hintCenterFaceInstructionText: "Posicione seu rosto no centro da câmera.",
    hintFaceOffCenterText: "Rosto fora do centro.",
    hintMatchIndicatorText: "Indicador de correspondência.",
    waitingCameraPermissionText:
      "Aguardando permissão para acessar a câmera...",
    cameraNotFoundHeadingText: "Câmera não acessível",
    cameraNotFoundMessageText:
      "Verifique se uma câmera está conectada e se não há outro aplicativo usando a câmera. Talvez seja necessário acessar as configurações para conceder permissões de câmera, fechar todas as instâncias do navegador e tentar novamente.",
  },
};

const LivenessComponent = () => {
  const { handleLogout, language } = useGlobalContext();

  const [loading, setLoading] = useState(true);

  const [sessionId, setSessionId] = useState(null);

  const [data, setData] = useState(null);

  const [faceLivenessAnalysis, setFaceLivenessAnalysis] = useState(null);

  const [errorState, setErrorState] = useState(null);

  const isAuthVerified = useRef(false);

  const { tokens } = useTheme();

  const fetchCreateLiveness = useCallback(async () => {
    try {
      const appCheckToken = await getAppCheckToken();
      const token = localStorage.getItem("idToken"); // Obtén el token del almacenamiento local
      const response = await fetch(API_URL + "/api/fetch-create-liveness", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-Firebase-AppCheck": appCheckToken,
        },
        body: JSON.stringify({ token }),
      });

      if (!response.ok) {
        if (response.status === 401) {
          handleLogout();
        }
        console.error(`Error ${response.statusText}`);
        console.log("Status", response.status);
        handleLogout();
        return;
      }

      const data = await response.json();
      setSessionId(data.sessionId);
      setData(data);
      setLoading(false);
      isAuthVerified.current = true; // Marcar autenticación como verificada
    } catch (error) {
      console.error("Error fetching liveness session:", error);
    }
  }, [handleLogout]);

  useEffect(() => {
    if (!isAuthVerified.current) {
      fetchCreateLiveness();
    }
  }, [isAuthVerified, fetchCreateLiveness]);

  const handleAnalysisCompleteV2 = useCallback(async () => {
    const token = localStorage.getItem("idToken"); // Obtén el token del almacenamiento local

    console.log("V2 ", sessionId);
    try {
      const appCheckToken = await getAppCheckToken();
      const response = await fetch(API_URL + "/api/get-session-results", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-Firebase-AppCheck": appCheckToken,
        },
        body: JSON.stringify({ sessionId }),
      });
      const data1 = await response.json();
      setFaceLivenessAnalysis(true);
      setData(data1);
      setSessionId(null);
    } catch (error) {
      console.error("Error fetching liveness result:", error);
    }
  }, [sessionId]);

  const handleRetry = () => {
    setFaceLivenessAnalysis(null); // Restablece el estado para permitir un nuevo intento
    setLoading(true); // Establece el estado de carga a true
    fetchCreateLiveness();
  };

  return (
    <View>
      {loading ? (
        <Flex
          direction="column"
          alignItems="center"
          justifyContent="center"
          height="100vh"
        >
          <Loader width="5rem" height="5rem" />
          <Text>Carregando</Text>
        </Flex>
      ) : (
        <Flex
          padding={tokens.space.small}
          backgroundColor={tokens.colors.neutral[20]}
          minHeight={"100vh"}
          direction={"column"}
          alignItems={"center"}
        >
          <Flex
            direction={"column"}
            alignItems={"center"}
            marginTop={(faceLivenessAnalysis || errorState) && tokens.space.xxl}
            marginBottom={
              (faceLivenessAnalysis || errorState) && tokens.space.large
            }
            gap={tokens.space.large}
          >
            {faceLivenessAnalysis ? (
              <Card
                borderRadius={8}
                width={"100%"}
                maxWidth={500}
                marginTop={tokens.space.large}
                padding={tokens.space.medium}
              >
                {data && data.status === "CHECK_RETRY" && (
                  <CustomAlertComponent
                    type="warning"
                    title="Falha na verificação"
                    message="Sua tentativa de verificação de identidade não foi bem-sucedida. Tente novamente."
                    action={
                      <Button
                        colorTheme="overlay"
                        marginTop={20}
                        onClick={handleRetry}
                      >
                        Tentar novamente
                      </Button>
                    }
                  />
                )}

                {data && data.status === "REJECTED" && (
                  <CustomAlertComponent
                    type="error"
                    title="Operação concluída"
                    message="Por favor, entre em contato imediatamente com nosso suporte."
                    action={
                      <Button
                        colorTheme="overlay"
                        marginTop={20}
                        onClick={() =>
                          window.open(
                            "https://www.basspago.com.br/suporte",
                            "_blanck"
                          )
                        }
                      >
                        Contate o suporte
                      </Button>
                    }
                  />
                )}

                {data && data.status === "COMPLETED" && (
                  <CustomAlertComponent
                    type="success"
                    title="Operação concluída"
                    message="Sua verificação foi concluída com sucesso e está em análise por nossa equipe de segurança. Você será notificado assim que for aprovada."
                  />
                )}

                {data && data.status === "TOO_MANY_ATEMPS_CREATE_SESSION" && (
                  <CustomAlertComponent
                    type="error"
                    title="Operação concluída"
                    message="Por favor, entre em contato imediatamente com nosso suporte."
                    action={
                      <Button
                        colorTheme="overlay"
                        marginTop={20}
                        onClick={() =>
                          window.open(
                            "https://www.basspago.com.br/suporte",
                            "_blanck"
                          )
                        }
                      >
                        Contate o suporte
                      </Button>
                    }
                  />
                )}
              </Card>
            ) : errorState === "TIMEOUT" ? (
              <Card
                borderRadius={8}
                width={"100%"}
                maxWidth={500}
                marginTop={tokens.space.large}
                padding={tokens.space.medium}
              >
                <TimeoutComponent
                  action={
                    <Button
                      colorTheme="overlay"
                      margin={20}
                      onClick={() => {
                        setErrorState(null);
                        handleRetry();
                      }}
                    >
                      Tentar novamente
                    </Button>
                  }
                />
              </Card>
            ) : (
              <FaceLivenessDetector
                sessionId={sessionId}
                region="us-east-1"
                onAnalysisComplete={handleAnalysisCompleteV2}
                onError={(error) => {
                  console.error(error);
                  setErrorState(error.state);
                }}
                displayText={dictionary[language]}
                components={{
                  PhotosensitiveWarning: () => {
                    return (
                      <Alert
                        variation="warning"
                        isDismissible={false}
                        hasIcon={true}
                      >
                        Tenha cuidado se você for fotossensível.
                      </Alert>
                    );
                  },
                  ErrorView: ({ children }) => {
                    return (
                      <Card
                        borderRadius={8}
                        width={"100%"}
                        maxWidth={500}
                        marginTop={tokens.space.large}
                        padding={tokens.space.medium}
                      >
                        <CustomAlertComponent
                          type="error"
                          title="Um erro ocorreu"
                          message={children}
                          action={
                            <Button
                              colorTheme="overlay"
                              marginTop={20}
                              onClick={handleRetry}
                            >
                              Tentar novamente
                            </Button>
                          }
                        />
                      </Card>
                    );
                  },
                }}
              />
            )}

            <Image
              alt="BassPago logo"
              src="/images/logo-basspago.svg"
              objectFit="initial"
              objectPosition="50% 50%"
              backgroundColor="initial"
              height={40}
              width={112.5}
              opacity="100%"
            />
          </Flex>
        </Flex>
      )}
    </View>
  );
};

export default LivenessComponent;
