import React from "react";
import "./App.css";
import { Amplify } from "aws-amplify";
import { ThemeProvider } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import awsexports from "./aws-exports";
import { theme } from "./config/theme";
import { GlobalProvider } from "./GlobalContext";
import { AppRoutes } from "./routes";

Amplify.configure(awsexports);

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalProvider>
        <AppRoutes />
      </GlobalProvider>
    </ThemeProvider>
  );
};

export default App;
