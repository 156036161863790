import { getToken } from "firebase/app-check";
import { APP_CHECK } from "../config/firebase";

/** **************************************
 * Get App Check token
 *************************************** */
export const getAppCheckToken = async () => {
  try {
    const appCheckTokenResponse = await getToken(APP_CHECK, true);

    return appCheckTokenResponse.token;
  } catch (error) {
    console.error("Error during getting app check token:", error);
    throw new Error("error.reCaptchaInvalid");
  }
};
