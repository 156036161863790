import { Flex, useTheme, Text, Alert, Link } from "@aws-amplify/ui-react";
import { DangerIcon } from "../../layout/icons";

export const TimeoutComponent = ({ action }) => {
  const { tokens } = useTheme();

  return (
    <Flex
      direction={"column"}
      alignItems={"center"}
      gap={tokens.space.large}
      padding={tokens.space.large}
    >
      <Flex
        width={96}
        height={96}
        alignItems="center"
        justifyContent="center"
        backgroundColor="rgba(255, 86, 48, 0.08)"
        color="#FF5630"
        borderRadius="100%"
      >
        <DangerIcon width={40} />
      </Flex>

      <Text fontSize={20} fontWeight={700} variation="primary">
        Limite de tempo excedido
      </Text>

      <Text textAlign="center">
        Você excedeu o limite de tempo para verificação de identidade. Tente
        novamente.
      </Text>

      {action}

      <Alert
        variation="info"
        isDismissible={false}
        hasIcon={true}
        borderRadius={8}
        fontSize={14}
        backgroundColor="rgba(0, 184, 217, 0.08)"
        color="#006C9C"
      >
        Certifique-se de que sua câmera esteja bem iluminada e limpa.
        Certifique-se de que seu rosto esteja totalmente visível e evite
        quaisquer obstruções.
      </Alert>

      <Text textAlign="center" color="#637381" fontSize={14}>
        Se você continuar tendo problemas{" "}
        <Link
          href="https://www.basspago.com.br/suporte"
          color="#0C68E9"
          isExternal={true}
          fontWeight={600}
        >
          Contate o suporte
        </Link>{" "}
        para obter mais assistência.
      </Text>
    </Flex>
  );
};
