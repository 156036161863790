export const theme = {
  name: "default-theme",
  tokens: {
    components: {
      link: {
        focus: {
          color: { value: "{colors.blue.80}" },
        },
        hover: {
          color: { value: "{colors.blue.60}" },
        },
        visited: {
          color: { value: "{colors.blue.80}" },
        },
      },
    },
  },
};
