import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  // Auth validation
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Language
  const [language, setLanguage] = useState("pt");

  // Login user saving token
  const handleLogin = useCallback((data) => {
    localStorage.setItem("idToken", data.id_token);
    setIsAuthenticated(true);
  }, []);

  // Logout user clear token
  const handleLogout = useCallback(() => {
    localStorage.removeItem("idToken");
    setIsAuthenticated(false);
  }, []);

  useEffect(() => {
    if (localStorage.getItem("idToken") !== null) {
      setIsAuthenticated(true);
    }
  }, []);

  // Global provider value memoized
  const memoizedValue = useMemo(
    () => ({
      isAuthenticated,
      language,
      setLanguage,
      handleLogin,
      handleLogout,
    }),
    [isAuthenticated, language, setLanguage, handleLogin, handleLogout]
  );

  return (
    <GlobalContext.Provider value={memoizedValue}>
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);

  if (!context) {
    throw new Error(
      "useGlobalContext: Context must be used inside GlobalProvider"
    );
  }

  return context;
};
