import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Flex,
  Loader,
  useTheme,
  Text,
  Button,
  Image,
} from "@aws-amplify/ui-react";

import { useNavigate } from "react-router-dom";
import { API_URL } from "../config";
import { useGlobalContext } from "../GlobalContext";
import { getAppCheckToken } from "../utils/helper";
import { WarningIcon } from "../layout/icons";

const LoginKeyComponent = () => {
  const { handleLogin } = useGlobalContext();

  const { key } = useParams();

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState("");

  const { tokens } = useTheme();

  const navigate = useNavigate();

  // Aquí podrías usar la `key` para realizar alguna lógica de autenticación
  const handleKeyLogin = async (e) => {
    // Simulando una autenticación con la `key`
    try {
      const appCheckToken = await getAppCheckToken();

      const response = await fetch(
        `${API_URL}/api/authenticate-liveness-by-token`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Firebase-AppCheck": appCheckToken,
          },
          body: JSON.stringify({ token: key }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        setLoading(false);
        let errorMessage = "Ocorreu um erro. Por favor, tente de novo.";

        if (errorData.message === "error.http.401") {
          errorMessage = "Email ou senha incorreta";
        } else if (errorData.message === "error.NoLivenessIsRequired") {
          errorMessage = "Não há teste de vida habilitado";
        } else if (errorData.detail) {
          errorMessage = errorData.detail;
        } else if (errorData.message) {
          errorMessage = errorData.message;
        }
        throw new Error(errorMessage);
      }

      const data = await response.json();
      setError("");

      handleLogin?.(data);

      navigate("/liveness");

      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);

    if (key) handleKeyLogin();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  const renderImageFooter = (
    <Image
      alt="BassPago logo"
      src="/images/logo-basspago.svg"
      objectFit="initial"
      objectPosition="50% 50%"
      backgroundColor="initial"
      height={40}
      width={112.5}
      opacity="100%"
    />
  );

  if (loading) {
    return (
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <Loader width="5rem" height="5rem" />
        <Text>Carregando</Text>
      </Flex>
    );
  }

  if (error) {
    return (
      <Flex
        direction={"column"}
        alignItems={"center"}
        marginTop={tokens.space.xxl}
        marginBottom={tokens.space.large}
        gap={tokens.space.xxxl}
        maxWidth={400}
        marginLeft="auto"
        marginRight="auto"
        textAlign="center"
      >
        <Flex
          direction={"column"}
          alignItems={"center"}
          gap={tokens.space.large}
        >
          <Flex
            width={96}
            height={96}
            alignItems="center"
            justifyContent="center"
            backgroundColor="rgba(255, 171, 0, 0.08)"
            color="#FFAB00"
            borderRadius="100%"
          >
            <WarningIcon width={40} />
          </Flex>

          <Text fontSize={20} fontWeight={700} variation="primary">
            Link Expirado
          </Text>

          <Text>
            O link que você usou para verificar sua identidade expirou. Solicite
            um novo link de verificação para continuar.
          </Text>

          <Button
            colorTheme="overlay"
            marginTop={20}
            onClick={() =>
              window.open("https://www.basspago.com.br/suporte", "_blanck")
            }
          >
            Contate o suporte
          </Button>
        </Flex>

        {renderImageFooter}
      </Flex>
    );
  }

  return null;
};
export default LoginKeyComponent;
