export const API_URL = process.env.REACT_APP_PUBLIC_API_URL;

export const RECAPTCHA_SITE_KEY =
  process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY;

export const FIREBASE_APP = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY ?? "",
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ?? "",
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID ?? "",
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET ?? "",
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID ?? "",
  appId: process.env.REACT_APP_FIREBASE_APPID ?? "",
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID ?? "",
};
