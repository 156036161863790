import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { FIREBASE_APP, RECAPTCHA_SITE_KEY } from "../config";

// export const firebaseConfig = {
//   apiKey: "AIzaSyB3HJcDcqUgBJ7V_XvD-lXCGIeBAChGn4s",
//   authDomain: "nuno-capital.firebaseapp.com",
//   databaseURL: "https://nuno-capital.firebaseio.com",
//   projectId: "nuno-capital",
//   storageBucket: "nuno-capital.appspot.com",
//   messagingSenderId: "54131464500",
//   appId: "1:54131464500:web:481ad3f6ab2175f9686d61",
//   measurementId: "G-7H7CMH28CE",
// };

// const f_app = firebase.initializeApp(firebaseConfig);

const isFirebase = !!FIREBASE_APP.appId;

export const firebaseApp = isFirebase ? initializeApp(FIREBASE_APP) : {};

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
export const APP_CHECK = isFirebase
  ? initializeAppCheck(firebaseApp, {
      provider: new ReCaptchaV3Provider(RECAPTCHA_SITE_KEY),

      // Optional argument. If true, the SDK automatically refreshes App Check
      // tokens as needed.
      isTokenAutoRefreshEnabled: true,
    })
  : {};
