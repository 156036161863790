import { Flex, useTheme, Text } from "@aws-amplify/ui-react";
import { ErrorIcon, SuccessIcon, WarningIcon } from "../../layout/icons";

export const CustomAlertComponent = ({ type, title, message, action }) => {
  const { tokens } = useTheme();

  return (
    <Flex
      direction={"column"}
      alignItems={"center"}
      gap={tokens.space.large}
      textAlign="center"
      padding={tokens.space.large}
    >
      <Flex
        width={96}
        height={96}
        alignItems="center"
        justifyContent="center"
        backgroundColor={
          type === "error"
            ? "rgba(255, 86, 48, 0.08)"
            : type === "success"
            ? "rgba(34, 197, 94, 0.08)"
            : "rgba(255, 171, 0, 0.08)"
        }
        color={
          type === "error"
            ? "#FF5630"
            : type === "success"
            ? "#22C55E"
            : "#FFAB00"
        }
        borderRadius="100%"
      >
        {type === "error" ? (
          <ErrorIcon width={40} />
        ) : type === "success" ? (
          <SuccessIcon width={40} />
        ) : (
          <WarningIcon width={40} />
        )}
      </Flex>

      <Text fontSize={20} fontWeight={700} variation="primary">
        {title}
      </Text>

      <Text>{message}</Text>

      {action}
    </Flex>
  );
};
