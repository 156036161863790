import { Icon } from "@aws-amplify/ui-react";

export const EyeBoldIcon = ({ width }) => (
    <Icon ariaLabel="eye-bold" viewBox={{ width: 24, height: 24 }} width={width || 24} height={width || 24}>
        <path
            fill="currentColor"
            d="M9.75 12a2.25 2.25 0 1 1 4.5 0a2.25 2.25 0 0 1-4.5 0"
        ></path>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M2 12c0 1.64.425 2.191 1.275 3.296C4.972 17.5 7.818 20 12 20c4.182 0 7.028-2.5 8.725-4.704C21.575 14.192 22 13.639 22 12c0-1.64-.425-2.191-1.275-3.296C19.028 6.5 16.182 4 12 4C7.818 4 4.972 6.5 3.275 8.704C2.425 9.81 2 10.361 2 12m10-3.75a3.75 3.75 0 1 0 0 7.5a3.75 3.75 0 0 0 0-7.5"
            clipRule="evenodd"
        ></path>
    </Icon>
);
