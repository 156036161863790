import React, { useCallback, useState } from "react";
import {
  Flex,
  useTheme,
  Card,
  Image,
  Heading,
  Input,
  Label,
  PasswordField,
  Button,
  Alert,
} from "@aws-amplify/ui-react";
import { API_URL } from "../config";
import { useGlobalContext } from "../GlobalContext";
import { getAppCheckToken } from "../utils/helper";

const LoginForm = () => {
  const { handleLogin } = useGlobalContext();

  const [email, setEmail] = useState("");

  const [password, setPassword] = useState("");

  const [error, setError] = useState("");

  const [loading, setLoading] = useState(false);

  const { tokens } = useTheme();

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      // Aquí deberías implementar tu lógica de autenticación
      setLoading(true);

      try {
        const appCheckToken = await getAppCheckToken();

        const response = await fetch(`${API_URL}/api/authenticate-liveness`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Firebase-AppCheck": appCheckToken,
          },
          body: JSON.stringify({ username: email, password: password }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          setLoading(false);
          let errorMessage = "Ocorreu um erro. Por favor, tente de novo.";

          if (errorData.message === "error.http.401") {
            errorMessage = "Email ou senha incorreta";
          } else if (errorData.message === "error.NoLivenessIsRequired") {
            errorMessage = "Não há teste de vida habilitado";
          } else if (errorData.detail) {
            errorMessage = errorData.detail;
          } else if (errorData.message) {
            errorMessage = errorData.message;
          }
          throw new Error(errorMessage);
        }

        const data = await response.json();

        setError("");

        handleLogin?.(data);

        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    },
    [email, handleLogin, password]
  );

  const renderHader = (
    <Flex
      direction={"column"}
      gap={tokens.space.xxs}
      marginTop={tokens.space.medium}
      marginBottom={tokens.space.medium}
    >
      <Heading
        level={4}
        textAlign={"center"}
        fontWeight={tokens.fontWeights.bold}
        fontSize={26}
      >
        Iniciar Sessão
      </Heading>
    </Flex>
  );

  const renderForm = (
    <form style={{ width: "100%" }} onSubmit={handleSubmit}>
      <Flex
        direction="column"
        width="100%"
        maxWidth={400}
        margin="auto"
        gap={tokens.space.medium}
        paddingLeft={tokens.space.large}
        paddingRight={tokens.space.large}
      >
        {!!error && (
          <Alert
            variation="error"
            isDismissible={true}
            hasIcon={true}
            onDismiss={() => setError("")}
          >
            {error}
          </Alert>
        )}

        {/* Email */}
        <Flex direction="column" gap="relative.xxs">
          <Label htmlFor="email">Correio eletrônico:</Label>
          <Input
            type="email"
            id="email"
            name="email"
            isRequired
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
        </Flex>

        {/* Password */}
        <PasswordField
          id="password"
          name="password"
          label="Senha"
          isRequired
          onChange={(event) => {
            setPassword(event.target.value);
          }}
        />

        <Flex justifyContent="end" marginTop={tokens.space.large}>
          <Button
            type="submit"
            variation="primary"
            colorTheme="info"
            isLoading={loading}
            loadingText="Iniciar Sessão"
          >
            Iniciar Sessão
          </Button>
        </Flex>
      </Flex>
    </form>
  );

  return (
    <Flex
      padding={tokens.space.large}
      backgroundColor={tokens.colors.neutral[20]}
      minHeight={"100vh"}
      direction={"column"}
      alignItems={"center"}
    >
      <Card
        borderRadius={8}
        width={"100%"}
        maxWidth={500}
        marginTop={tokens.space.large}
        padding={tokens.space.medium}
      >
        <Flex
          direction={"column"}
          alignItems={"center"}
          marginTop={tokens.space.xxl}
          marginBottom={tokens.space.large}
          gap={tokens.space.large}
        >
          <Image alt="Bass Pago logo" src="/logo-dark.png" width={180} />

          {renderHader}

          {renderForm}
        </Flex>
      </Card>
    </Flex>
  );
};

export default LoginForm;
